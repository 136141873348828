<template>
  <v-card>
    <v-row class="ma-0 pa-3 pb-5" align="center" justify="space-between">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex pa-0">
        <v-btn color="primary" to="/devices/create">
          <v-icon left>mdi-plus</v-icon>Create
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="d-flex pa-0 pt-4 pt-sm-0 pt-md-0 pt-lg-0 pt-xl-0 justify-end">
          <v-text-field v-model.trim="search" append-icon="mdi-magnify" class="pt-0 w-full" label=""
            placeholder="Search Device" :disabled="loading" hide-details @input="searchDatatable"></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{item}">
        <v-icon small class="mr-2 cursor-pointer" color="primary" @click="$router.push(`/devices/${item.id}`)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2 cursor-pointer" color="danger" @click="notify=item.id">
          mdi-trash-can
        </v-icon>
      </template>
    </v-data-table>
    <Notify v-if="notify" @close="notify=null" :loading="deleteLoading" @saved="deleteDeviceItem()"
      title="are you sure" success=", delete it!"/>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

import { Notify } from "@/components/common";

export default {
  components: { Notify },
  data() {
    return {
      totalItems: 0,
      tableItems: [],
      search: '',
      loading: true,
      options: {},
      notify: null,
      deleteLoading: false,
      headers: [
          { text: 'Location Name', value: 'location_name'},
          { text: 'Type', value: 'type'},
          { text: "Owner", value: "owner" },
          {text: 'Action', value: 'action', sortable: false}
      ],
      pageLink: `${window.VUE_APP_MS_URL}agent/devices`,
      prevOptions: null,
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.searchDatatable = _.debounce(this.searchDatatable, 500);
  },
  methods: {
    ...mapActions(['getModel', 'deleteDevice']),

    searchDatatable() { this.getDataFromApi('search') },
    getDataFromApi (type) {
      this.loading = true;
      this.getDTDetails(type).then(data => {
        this.tableItems = data.items;
        this.totalItems = data.total;
        this.loading = false;
      }).catch(err => {
        this.tableItems = [];
        this.totalItems = 0;
        this.loading = false;
      });
    },
    getDTDetails (type) {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options,
          endPoint = new URL(this.pageLink);
          endPoint.searchParams.set("per_page", itemsPerPage);
          endPoint.searchParams.set("page", page);
          if(type) endPoint.searchParams.set("page", 1);
          if(this.search !== '') {
            endPoint.searchParams.set("q", this.search);
          }
       this.getAPIdata(endPoint, this.options, type).then(resp => {
          let { items, total } = resp;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy[0]]
              const sortB = b[sortBy[0]]

              if (sortDesc[0]) {
                if (sortA < sortB) return 1
                if (sortA > sortB) return -1
                return 0
              } else {
                if (sortA < sortB) return -1
                if (sortA > sortB) return 1
                return 0
              }
            })
          }
          this.prevOptions = this.options;
          resolve({
            items,
            total,
          })

        }).catch(err => reject(err));
      });
    },
    getAPIdata(endPoint, options, type) {
      const { page, itemsPerPage } = options;
      return new Promise((resolve, reject) => {
        if (!type && this.prevOptions && this.prevOptions.page == page && this.prevOptions.itemsPerPage == itemsPerPage) {
            resolve({items: this.tableItems, total: this.totalItems});
            return true;
        }
        this.getModel(endPoint.toString()).then(resp => {
          resolve(resp);
        }).catch(err => reject(err));
      });
    },
    deleteDeviceItem() {
      this.deleteLoading = true;   
      this.deleteDevice(this.notify).then(() => {
        this.notify = null;
        this.deleteLoading = false;
        this.getDataFromApi('delete');
      }).catch(err => this.deleteLoading = false);
    }
  },
};
</script>
